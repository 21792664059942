import React from 'react'
import Link from 'gatsby-link'

const Page = props => (
  <div style={{
    textAlign: 'center',
    margin: '20rem 0'
  }}>
    <h1>
      2017年度
    </h1>

    <p>
      期間: 2017-03-15 ~ 2017-09-31
    </p>

    <a
      href="/__/plusuno/_koukoku/2017/kessan.pdf"
    >
      決算書
    </a>
  </div>
)
export default Page